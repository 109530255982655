<template>
  <div class="catalyst-labs">
    <!-- Navbar with logo and navigation -->
    <nav class="navbar is-transparent is-fixed-top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item logo" @click="scrollToSection('hero')" aria-label="Scroll to top">
          <img 
            src="@/assets/catalystlabs.png" 
            alt="Catalyst Labs Logo" 
            class="navbar-logo"
            :style="{ height: logoHeight + 'rem' }"
            loading="lazy"
          >
        </a>
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" @click="toggleMobileMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" :class="{ 'is-active': mobileMenuOpen }">
        <div class="navbar-end">
          <a class="navbar-item" @click="scrollToSection('services')">Services</a>
          <a class="navbar-item" @click="scrollToSection('about')">About</a>
          <a class="navbar-item" @click="scrollToSection('contact')">Contact</a>
        </div>
      </div>
    </nav>

    <!-- Hero Section -->
    <section id="hero" class="hero is-fullheight-with-navbar">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-vcentered">
            <div class="column is-6">
              <h1 class="title is-1 hero-title">
                <span class="text-gradient">Transforming</span><br>
                Ideas Into Reality
              </h1>
              <h2 class="subtitle is-4 hero-subtitle">
                Innovate faster with our cutting-edge AI,<br>
                design, and development solutions
              </h2>
              <div class="hero-stats">
                <div class="stat-item">
                  <div class="stat-number">150+</div>
                  <div class="stat-label">Successful Projects</div>
                </div>
                <div class="stat-item">
                  <div class="stat-number">85+</div>
                  <div class="stat-label">Satisfied Clients</div>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="contact-card">
                <div class="card-content">
                  <h3 class="title is-4 has-text-centered">Start Your Project</h3>
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Services Section -->
    <section id="services" class="section services-section">
      <div class="container">
        <div class="section-header">
          <h2 class="section-title">Our <span class="text-gradient">Services</span></h2>
          <p class="section-description">Comprehensive solutions to bring your vision to life</p>
        </div>
        
        <div class="columns is-multiline is-centered">
          <div class="column is-3" v-for="service in services" :key="service.title">
            <div class="service-card">
              <div class="service-icon">
                <i :class="service.icon" aria-hidden="true"></i>
              </div>
              <h3 class="service-title">{{ service.title }}</h3>
              <p class="service-description">{{ service.description }}</p>
              <div class="service-features">
                <span v-for="feature in service.features" :key="feature" class="feature-tag">{{ feature }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- About Us Section -->
    <section id="about" class="section about-section">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-6">
            <div class="about-image">
              <div class="image-wrapper">
                <img 
                  src="@/assets/team-placeholder.jpg" 
                  alt="Catalyst Labs team working together" 
                  loading="lazy"
                  class="team-image"
                >
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="section-header">
              <h2 class="section-title">About <span class="text-gradient">Catalyst Labs</span></h2>
            </div>
            <p class="about-text">
              We're pioneers in digital transformation, combining AI innovation with exceptional design 
              and development to create solutions that propel businesses forward. Our team of experts 
              delivers cutting-edge technology with a human touch.
            </p>
            <div class="about-stats">
              <div class="about-stat">
                <div class="stat-number">50+</div>
                <div class="stat-label">Team Members</div>
              </div>
              <div class="about-stat">
                <div class="stat-number">15</div>
                <div class="stat-label">Countries Served</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer class="footer">
      <div class="container">
        <div class="footer-content">
          <div class="footer-logo">
            <img src="@/assets/catalystlabs-white.png" alt="Catalyst Labs" loading="lazy">
          </div>
          <div class="footer-links">
            <a @click="scrollToSection('services')">Services</a>
            <a @click="scrollToSection('about')">About</a>
            <a @click="scrollToSection('contact')">Contact</a>
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div class="footer-social">
            <a href="#" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
            <a href="#" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
            <a href="#" aria-label="LinkedIn"><i class="fab fa-linkedin"></i></a>
          </div>
        </div>
        <div class="footer-copyright">
          <p>&copy; 2025 Catalyst Labs. All rights reserved. Innovating the future, today.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import ContactForm from "@/components/ContactForm.vue";

export default {
  components: {
    ContactForm
  },
  data() {
    return {
      logoHeight: 2.5,
      mobileMenuOpen: false,
      services: [
        { 
          title: "Design", 
          description: "Crafting visually stunning and user-centric experiences", 
          icon: "fas fa-paint-brush",
          features: ["UI/UX Design", "Prototyping", "Design Systems"]
        },
        { 
          title: "Development", 
          description: "Building high-performance and scalable software", 
          icon: "fas fa-code",
          features: ["Web Apps", "Mobile Apps", "API Integration"]
        },
        { 
          title: "AI Solutions", 
          description: "Transforming businesses through AI-powered innovation", 
          icon: "fas fa-robot",
          features: ["Machine Learning", "Computer Vision", "NLP"]
        },
        { 
          title: "Consulting", 
          description: "Strategic insights to optimize your business solutions", 
          icon: "fas fa-lightbulb",
          features: ["Tech Strategy", "Digital Transformation", "Process Optimization"]
        }
      ]
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      this.mobileMenuOpen = false;
    },
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    handleScroll: debounce(function() {
      const scrollY = window.scrollY || window.pageYOffset;
      this.logoHeight = Math.max(2, 2.5 - scrollY * 0.01);
    }, 10)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

:root {
  --primary: #3B82F6;
  --primary-dark: #2563EB;
  --gradient: linear-gradient(135deg, #3B82F6 0%, #2DD4BF 100%);
  --text-dark: #1F2937;
  --text-light: #6B7280;
  --bg-light: #F9FAFB;
  --bg-dark: #1A2A44;
}

.catalyst-labs {
  font-family: 'Inter', sans-serif;
  color: var(--text-dark);
  position: relative;
  overflow-x: hidden;
  background-color: white;
}

/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.navbar-brand {
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 2.5rem;
  width: auto;
  max-height: none;
  transition: all 0.3s ease;
}

.navbar-item:hover .navbar-logo {
  transform: scale(1.05);
  opacity: 0.9;
}

.navbar-burger {
  height: auto;
}

.navbar-menu.is-active {
  display: block;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
}

/* Hero Section */
.hero {
  padding-top: 5rem;
  min-height: 90vh;
}

.hero-body {
  padding: 3rem 1.5rem;
}

.hero-title {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 1rem;
  word-break: keep-all;
}

.text-gradient {
  background: var(--gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: var(--text-light);
  margin-bottom: 2rem;
  line-height: 1.5;
  max-width: 100%;
}

.hero-stats {
  display: flex;
  gap: 2rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.stat-item {
  min-width: 120px;
  text-align: center;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary);
  margin-bottom: 0.5rem;
}

.stat-label {
  font-size: 1rem;
  color: var(--text-light);
  font-weight: 500;
}

.contact-card {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  background: white;
  z-index: 1;
}

.card-content {
  padding: 2.5rem;
}

/* Services Section */
.services-section {
  padding: 6rem 0;
  background: var(--bg-light);
}

.section-header {
  text-align: center;
  margin-bottom: 4rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.section-description {
  font-size: 1.1rem;
  color: var(--text-light);
  max-width: 600px;
  margin: 0 auto;
}

.service-card {
  background: white;
  border-radius: 16px;
  padding: 2.5rem 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.service-icon {
  width: 80px;
  height: 80px;
  background: rgba(59, 130, 246, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: var(--primary);
  font-size: 2rem;
}

.service-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.service-description {
  color: var(--text-light);
  margin-bottom: 1.5rem;
  flex-grow: 1;
}

.service-features {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.feature-tag {
  background: rgba(59, 130, 246, 0.1);
  color: var(--primary);
  padding: 0.3rem 0.8rem;
  border-radius: 50px;
  font-size: 0.8rem;
  font-weight: 500;
}

/* About Section */
.about-section {
  padding: 6rem 0;
  background: white;
}

.about-image {
  position: relative;
  height: 100%;
}

.image-wrapper {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 400px;
}

.team-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-text {
  color: var(--text-light);
  margin-bottom: 2rem;
  line-height: 1.7;
}

.about-stats {
  display: flex;
  gap: 3rem;
  margin-top: 2rem;
}

.about-stat {
  text-align: center;
}

.about-stat .stat-number {
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary);
  margin-bottom: 0.25rem;
}

.about-stat .stat-label {
  font-size: 0.9rem;
  color: var(--text-light);
}

/* Footer */
.footer {
  padding: 3rem 0;
  background: var(--bg-dark);
  color: white;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.footer-logo img {
  height: 2.5rem;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.footer-links a {
  color: white;
  cursor: pointer;
  transition: opacity 0.3s;
}

.footer-links a:hover {
  opacity: 0.8;
}

.footer-social {
  display: flex;
  gap: 1rem;
}

.footer-social a {
  color: white;
  font-size: 1.2rem;
}

.footer-copyright {
  text-align: center;
  font-size: 0.9rem;
  opacity: 0.8;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-subtitle {
    font-size: 1.25rem;
  }
  
  .service-card {
    padding: 2rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .hero {
    padding-top: 4rem;
  }
  
  .hero-body {
    padding: 3rem 1.5rem;
  }
  
  .columns.is-vcentered {
    flex-direction: column;
  }
  
  .column.is-6 {
    width: 100%;
  }
  
  .hero-title {
    font-size: 2rem;
    text-align: center;
  }
  
  .hero-subtitle {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .hero-stats {
    justify-content: center;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .contact-card {
    margin-top: 3rem;
  }
  
  .navbar-logo {
    height: 2rem;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 1.8rem;
  }
  
  .stat-number {
    font-size: 2rem;
  }
  
  .hero-stats {
    gap: 1.5rem;
  }
  
  .about-stats {
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .navbar-logo {
    height: 1.8rem;
  }
  
  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
</style>